/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: 'Inter Fallback';src: local("Arial");ascent-override: 90.44%;descent-override: 22.52%;line-gap-override: 0.00%;size-adjust: 107.12%
}.__className_d65c78 {font-family: 'Inter', 'Inter Fallback';font-style: normal
}.__variable_d65c78 {--font-inter: 'Inter', 'Inter Fallback'
}

/* thai */
@font-face {
  font-family: 'Noto Sans Thai';
  font-style: normal;
  font-weight: 100 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/14c8faeaedb3b528-s.p.woff2) format('woff2');
  unicode-range: U+02D7, U+0303, U+0331, U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* latin-ext */
@font-face {
  font-family: 'Noto Sans Thai';
  font-style: normal;
  font-weight: 100 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/fa7ac48ba9bfcd84-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Noto Sans Thai';
  font-style: normal;
  font-weight: 100 900;
  font-stretch: 100%;
  font-display: swap;
  src: url(/_next/static/media/fdeb77dbe171451b-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: 'Noto Sans Thai Fallback';src: local("Arial");ascent-override: 99.79%;descent-override: 42.32%;line-gap-override: 0.00%;size-adjust: 106.33%
}.__className_10e463 {font-family: 'Noto Sans Thai', 'Noto Sans Thai Fallback';font-style: normal
}.__variable_10e463 {--font-thai: 'Noto Sans Thai', 'Noto Sans Thai Fallback'
}

@font-face {
font-family: 'fontAcumin';
src: url(/_next/static/media/d1b2d5d709df8f70-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'fontAcumin';
src: url(/_next/static/media/78f5aaebd0c707bc-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: 'fontAcumin';
src: url(/_next/static/media/e229b5d74a21eb10-s.p.woff) format('woff');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: 'fontAcumin';
src: url(/_next/static/media/21957e3aa3f765c7-s.p.woff) format('woff');
font-display: swap;
font-weight: 700;
font-style: italic;
}@font-face {font-family: 'fontAcumin Fallback';src: local("Arial");ascent-override: 74.29%;descent-override: 26.78%;line-gap-override: 20.21%;size-adjust: 98.94%
}.__className_46be76 {font-family: 'fontAcumin', 'fontAcumin Fallback'
}.__variable_46be76 {--font-acumin: 'fontAcumin', 'fontAcumin Fallback'
}

@font-face {
font-family: 'fontButler';
src: url(/_next/static/media/e9df9e931370ff65-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'fontButler';
src: url(/_next/static/media/69f97f3dc2f4b9c1-s.p.woff) format('woff');
font-display: swap;
font-weight: 200;
font-style: normal;
}

@font-face {
font-family: 'fontButler';
src: url(/_next/static/media/e7a5691eebe24cea-s.p.woff) format('woff');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: 'fontButler';
src: url(/_next/static/media/293f07453a03ee2a-s.p.woff) format('woff');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: 'fontButler';
src: url(/_next/static/media/3fd19eb3a34c3c57-s.p.woff) format('woff');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: 'fontButler';
src: url(/_next/static/media/0651c6cfff128216-s.p.woff) format('woff');
font-display: swap;
font-weight: 800;
font-style: normal;
}

@font-face {
font-family: 'fontButler';
src: url(/_next/static/media/82af187f14ea35d9-s.p.woff) format('woff');
font-display: swap;
font-weight: 900;
font-style: normal;
}@font-face {font-family: 'fontButler Fallback';src: local("Arial");ascent-override: 76.99%;descent-override: 27.76%;line-gap-override: 20.95%;size-adjust: 95.47%
}.__className_69bd75 {font-family: 'fontButler', 'fontButler Fallback'
}.__variable_69bd75 {--font-butler: 'fontButler', 'fontButler Fallback'
}

@font-face {
font-family: 'fontMyriad';
src: url(/_next/static/media/898be05d7b9796c7-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'fontMyriad';
src: url(/_next/static/media/4866f0f390fdd5c4-s.p.woff) format('woff');
font-display: swap;
font-weight: 100;
font-style: normal;
}

@font-face {
font-family: 'fontMyriad';
src: url(/_next/static/media/2186db52937920a3-s.p.woff) format('woff');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: 'fontMyriad';
src: url(/_next/static/media/42dc1a277165cb6b-s.p.woff) format('woff');
font-display: swap;
font-weight: 700;
font-style: italic;
}

@font-face {
font-family: 'fontMyriad';
src: url(/_next/static/media/1948f9e42b48a8eb-s.p.woff) format('woff');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: 'fontMyriad';
src: url(/_next/static/media/b2a9f28c59e9e795-s.p.woff) format('woff');
font-display: swap;
font-weight: 700;
font-style: italic;
}@font-face {font-family: 'fontMyriad Fallback';src: local("Arial");ascent-override: 75.00%;descent-override: 25.00%;line-gap-override: 20.00%;size-adjust: 100.00%
}.__className_bdd69a {font-family: 'fontMyriad', 'fontMyriad Fallback'
}.__variable_bdd69a {--font-myriad: 'fontMyriad', 'fontMyriad Fallback'
}

@font-face {
font-family: 'fontMyriadCondensed';
src: url(/_next/static/media/b042c38cb2c1fe1e-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'fontMyriadCondensed';
src: url(/_next/static/media/2a8b47fc784682dd-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: 'fontMyriadCondensed';
src: url(/_next/static/media/f604d2fd8123d147-s.p.woff) format('woff');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: 'fontMyriadCondensed';
src: url(/_next/static/media/10ac53c90f0d8c74-s.p.woff) format('woff');
font-display: swap;
font-weight: 700;
font-style: italic;
}@font-face {font-family: 'fontMyriadCondensed Fallback';src: local("Arial");ascent-override: 108.15%;descent-override: 36.05%;line-gap-override: 28.84%;size-adjust: 69.35%
}.__className_43a886 {font-family: 'fontMyriadCondensed', 'fontMyriadCondensed Fallback'
}.__variable_43a886 {--font-myriad-condensed: 'fontMyriadCondensed', 'fontMyriadCondensed Fallback'
}

